<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="UID">
        <el-input v-model="dataForm.uid" placeholder="UID" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="addOrUpdateHandle(0)" type="primary">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%; ">

      <el-table-column prop="uid" header-align="center" align="center" label="UID">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.virUid ? '/' : scope.row.virUid }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="virName" header-align="center" align="center" label="角色名">
        <template slot-scope="scope">
          {{ getNickName(scope.row.virUid) }}
        </template>
      </el-table-column>
      <el-table-column prop="virDesc"  header-align="center" align="center" label="人物描述">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.virDesc ? '/' : scope.row.virDesc }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="atime" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column prop="status" sortable header-align="center" align="center" label="发布状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 0" type="primary">正常</el-tag>
            <el-tag v-if="scope.row.status === 1" type="info">禁止</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center"  label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="addOrUpdateHandle(scope.row)" style="color: rgba(236, 128, 141, 1)">编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                   :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                   layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>

  </div>
</template>
<script>
import AddOrUpdate from "./operate-tele-add-or-update";
import { langOpt, pickerOptions } from "@/views/common/commonOpt";


const sourceDataForm = {
  id: '',
  virUid: '',
  virDesc: '',
  status: '',
  atime: '',
  utime: '',
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      langOpt: langOpt,
      pickerOptions: pickerOptions,
      addOrUpdateVisible: false,
      uploadAnchorVisible:true,
      virtualUser: [],

    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  async created() {
    this.virtualUser = await this.$store.dispatch("GetVirtualUser", {});

  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/teleVirUser/list'),
        method: 'post',
        data: this.$http.adornData({
          uid: this.dataForm.uid,
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    getNickName(virUid){
      let ipOpt = this.virtualUser.find(item => item['uid'] == virUid)
      return !ipOpt ? "" : ipOpt['nickname']
    },
    // 导出
    exportList() {

      // window.open(
      //     this.$http.adornUrl('/cs/table/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
      //     "_blank"
      // );
      const exportData = {
        uid: this.dataForm.uid,
        page: this.pageIndex,
        limit: this.pageSize,
      }
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.location.href = this.$http.adornUrl('/admin/anchor/export') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`
    }

  },
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
</style>
